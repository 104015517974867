import React from "react"

import Layout from "../components/Layout"
import AboutUs from "../components/PrivacyPolicy"
import SEO from "../components/seo"

const AboutUsPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="About us" />
    <AboutUs />
  </Layout>
)

export default AboutUsPage
